import { useRouter } from 'next/router';
import { ArrowBack, PageNotFound, Text, TextEnum } from '@desquared/wave-components-library';

import PageLayout from '@/components/PageLayout';
import useWindowUnderThreshold from '@/hooks/useWindowUnderThreshold';
import { getLayoutProps } from '@/utils/layout/layout';
import { useTranslations } from 'next-intl';

import styles from '@/styles/PageNotFound.module.css';

export default function Custom404({ image }) {
  const isMobile = useWindowUnderThreshold(850);
  const router = useRouter();
  const t = useTranslations();
  const imageUrl = 'assets/wave-grocery/Empty-States-Default/404.png';

  const text = (
    <Text classOption={styles.text} type={isMobile ? TextEnum.SubHeading3 : TextEnum.SubHeading1}>
      {t('not_found.message')} <span className={styles.hashtag}>{t('not_found.first_hashtag')}</span>{' '}
      <span className={styles.hashtag}>{t('not_found.second_hashtag')}</span>
    </Text>
  );

  return (
    <main className={styles.container}>
      <PageNotFound
        img={{
          size: isMobile ? 'large' : 'medium',
          url: image ? image : `${process.env.NEXT_PUBLIC_HOSTNAME}${imageUrl}`,
          alt: 'Page Not Found',
        }}
        text={text}
        actions={[{ btnLabel: t('not_found.action_btn_label'), onClick: router.back, leftIcon: ArrowBack }]}
      />
    </main>
  );
}

Custom404.getLayout = function getLayout(page) {
  return <PageLayout>{page}</PageLayout>;
};

export async function getStaticProps({ locale }) {
  const layoutProps = await getLayoutProps({ headerProps: { selectedIndex: -1 } }, locale);
  const image = layoutProps?.emptyStates?.page_not_found?.url ? layoutProps?.emptyStates?.page_not_found?.url : null;
  const messages = {
    common: (await import(`../../prebuild-data/i18n/${locale}/common.json`)).default,
    not_found: (await import(`../../prebuild-data/i18n/${locale}/not_found.json`)).default,
  };

  return {
    props: {
      ...layoutProps,
      messages,
      image,
    },
  };
}
